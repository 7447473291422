import React, { FC, useCallback, useState } from 'react'
import Container from '@material-ui/core/Container'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ReviewSliderCard } from './review-slider-card'
import { default as SwiperClass } from 'swiper/types/swiper-class'
import { useReviewsStyles } from './reviews.styles'
import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { RoundedArrowIcon } from 'src/jsx-ico'
import { theme } from '@plandi/common'

const items = [
  {
    image: '/assets/reviewer1.png',
    // eslint-disable-next-line max-len
    text: 'Работаю на PlanDi недавно, но за короткий срок уже работаю в двух проектах. Понравилось, что, проживая в Киеве могу брать проекты с Москвы и выполнять их онлайн, за это отдельное спасибо',
    position: 'Инженер конструктор',
    name: 'Николай Ткаченко'
  },
  {
    image: '/assets/reviewer2.png',
    // eslint-disable-next-line max-len
    text: 'Хочу поделиться радостью и выразить свою благодарность компании PlanDi! Благодаря ее сервисам работать удаленно для проектировщика стало намного легче и безопаснее. А также постоянный поток заказов, что позволяет не сидеть без дела.',
    position: 'Техник – проектировщик',
    name: 'Юсуф Мамедов'
  },
  {
    image: '/assets/reviewer3.png',
    // eslint-disable-next-line max-len
    text: 'Наша компания не однократно опубликовывает заказы на разделы проекта инженерных сетей на данном сервисе, были оперативно найдены специалисты и добавлены в проект. Благодаря этому сервису нашли стабильных и профессиональных проектировщиков с хорошим опытом работы.',
    position: 'Руководитель ООО «Stron Holding»',
    name: 'Мацковяк Вадим'
  },
  {
    image: '/assets/reviewer4.png',
    // eslint-disable-next-line max-len
    text: 'Нарвался на неквалифицированного исполнителя на Вашем сайте, но благодаря вашей быстрой и профессиональной поддержки вопрос был решен моментально. Исполнителя заблокировали. Спасибо Вам большое!',
    position: 'Руководитель ТОО «Soft Interactive»',
    name: 'Грынькив Александр'
  },
  {
    image: '/assets/reviewer5.png',
    // eslint-disable-next-line max-len
    text: 'Зарегистрировавшись в Вашем Онлайн Офисе увидели много недоработок, а именно отсутствие CRM системы, которая бы подходила именно под проектную деятельность, отсутствие мессенджера и возможности создавать видео конференцию с командой. Надеемся, что наши пожелания будут учтены.',
    position: 'Руководитель ГК «Энергоэффективные технологии»',
    name: 'Жданов Дмитрий'
  }
]

export const Reviews: FC = () => {
  const classes = useReviewsStyles()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down(900))
  const isTablet = useMediaQuery(theme.breakpoints.down(1280))

  const [swiperInstace, setSwiperInstance] = useState<null | SwiperClass>(null)

  const handleNextSlide = useCallback(() => {
    if (!swiperInstace) return
    swiperInstace.slideNext()
  }, [swiperInstace])

  const handlePrevSlide = useCallback(() => {
    if (!swiperInstace) return
    swiperInstace.slidePrev()
  }, [swiperInstace])

  return (
    <Container maxWidth={false}>
      <div id={'reviews-slider'} />
      <Container className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title}>Нам доверяют</Typography>
            <div className={classes.controlButtonContainer}>
              <IconButton
                onClick={handlePrevSlide}
                className={clsx(classes.prevButton, classes.controlButton)}
                aria-label={'sliderControl'}
              >
                <RoundedArrowIcon
                  width={isMediumScreen ? '40px' : '48px'}
                  height={isMediumScreen ? '40px' : '48px'}
                />
              </IconButton>
              <IconButton
                onClick={handleNextSlide}
                className={classes.controlButton}
                aria-label={'sliderControl'}
              >
                <RoundedArrowIcon
                  width={isMediumScreen ? '40px' : '48px'}
                  height={isMediumScreen ? '40px' : '48px'}
                />
              </IconButton>
            </div>
          </div>
          <Typography className={classes.description}>
            Cамые успешные кейсы и отзывы от компаний
          </Typography>
        </div>
        <Grid item>
          <Swiper
            slidesPerView={isSmallScreen ? 1 : isTablet ? 2 : 3}
            spaceBetween={56}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            loop
          >
            {items.map((item, index) => (
              <SwiperSlide key={index} className={classes.cardWrapper}>
                <ReviewSliderCard
                  text={item.text}
                  reviewerImage={item.image}
                  reviewerName={item.name}
                  reviewerPosition={item.position}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Container>
    </Container>
  )
}
