import { useQuery, UseQueryOptions } from 'react-query'
import { newLandingApi } from '../new-landing/common'
import { components } from '@plandi/common/src/generated'
import { AxiosError } from 'axios'

export const useNewServicesSliderQuery = (
  options?: UseQueryOptions<
    components['schemas']['NewServicesSliderResource'],
    AxiosError,
    components['schemas']['NewServicesSliderResource']
  >
) => {
  const query = useQuery(
    ['SERVICES_SLIDER'],
    () => newLandingApi.getNewServicesSliderData(),
    options
  )
  return query
}
