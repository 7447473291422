import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useHeroStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // width: '100%',
      margin: '65px 0 0',
      // backgroundImage: 'url(/assets/hero.svg)',
      backgroundPosition: 'bottom right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',

      backgroundColor: '#EEEEEC',
      padding: '87px 0',
      [theme.breakpoints.down(1200)]: {
        padding: '48px 32px 300px',
        backgroundSize: 'auto 100% cover'
      },
      [theme.breakpoints.down(900)]: {
        margin: '55px 0 0',
        backgroundSize: '100% auto, cover',
        backgroundPosition: 'bottom center'
      }
      // backgroundColor: 'lightgray'
    },
    container: {
      maxWidth: '1360px',
      padding: 0
    },
    title: {
      fontSize: '56px',
      fontWeight: 600,
      fontStyle: 'normal',
      fontHeight: 'normal',
      lineHeight: 'normal',
      opacity: 1,
      '&>span': {
        fontSize: 'inherit',
        fontWeight: 'inherit'
      },
      [theme.breakpoints.down(900)]: {
        fontSize: '26px'
      }
    },
    description: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '36px',
      margin: '32px 0 40px',
      [theme.breakpoints.down(900)]: {
        fontSize: '16px',
        lineHeight: '22px'
      }
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      [theme.breakpoints.down(900)]: {
        justifyContent: 'space-between'
      }
    },
    button: {
      padding: '15px 28px',
      boxShadow: 'none',
      [theme.breakpoints.down(900)]: {
        padding: '15px 15px',
        fontSize: '14px',
        flex: '1 1 100%'
      }
    },
    buttonText: {
      textTransform: 'none',
      fontSize: '16px',
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: 'normal'
    }
  })
)
