import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'

import { components } from '@plandi/common/src/generated'
import { sharedApi } from '../api'
import { SEO_TEXTS } from '../keys'

export const useQuerySeoTexts = (
  options?: UseQueryOptions<
    components['schemas']['SEOTextResource'],
    AxiosError
  >
) => {
  return useQuery([SEO_TEXTS], sharedApi.getSEOText, {
    staleTime: 60 * 1000,
    ...options
  })
}
