/* eslint-disable max-len */
import { getApiClient } from '@plandi/common'
import { components } from '@plandi/common/src/generated'

export const newLandingApi = {
  getMainPageData: () =>
    getApiClient()
      .get<{ data: components['schemas']['HeadPageResource'] }>(
        'v1/content/page/main'
      )
      .then(({ data }) => data.data?.main),

  getBestSpecializations: () =>
    getApiClient()
      .get<{ data: components['schemas']['BestSpecializations'] }>(
        'v1/best/specializations'
      )
      .then(({ data }) => data?.data),

  getBestCompanies: (params: { mainActivitySpecializationsCompany: number }) =>
    getApiClient()
      .get<{ data: components['schemas']['ProfileCompanyResource'] }>(
        'v1/profiles/companies/best',
        { params }
      )
      .then(({ data }) => data.data?.data),

  getBestFactories: (params: { mainActivitySpecializationsFactory: number }) =>
    getApiClient()
      .get<{ data: components['schemas']['ProfileFactoryResource'] }>(
        'v1/profiles/plants/best',
        { params }
      )
      .then(({ data }) => data.data?.data),

  getBestFreelancer: (params: {
    type: string
    mainActivitySpecializationsSpecialist: number
  }) =>
    getApiClient()
      .get<{ data: components['schemas']['ProfileSpecialistResource'] }>(
        'v1/profiles/specialists/best',
        { params }
      )
      .then(({ data }) => data.data?.data),

  getBestAdverts: (params: {
    activitySpecializationsCompany?: number
    activitySpecializationsSpecialist?: number
  }) =>
    getApiClient()
      .get<{ data: components['schemas']['AdvertsResource'] }>(
        'v1/tenders/best/adverts',
        { params }
      )
      .then(({ data }) => data.data?.items),

  getBestModels: (queryModels: number) =>
    getApiClient()
      .post<{ data: components['schemas']['ModelMarket'] }>(
        `v1/market/models/feed/best?categoryIds=${queryModels}`
      )
      .then(({ data }) => data.data.items),

  getBestProjects: (queryProjects: number) =>
    getApiClient()
      .post<{ data: components['schemas']['ProjectMarket'] }>(
        `v1/market/projects/feed/best?categoryIds=${queryProjects}`
      )
      .then(({ data }) => data.data.items),

  getBestGoods: (queryGoods: number) =>
    getApiClient()
      .post<{ data: components['schemas']['GoodsMarket'] }>(
        `v1/market/goods/feed/best?rubricIds=${queryGoods}`
      )
      .then(({ data }) => data.data.data),

  sendEmail: (email: string) =>
    getApiClient()
      .post('v1/subscription/subscribe', { email })
      .then(({ data }) => data),

  getBlogData: () =>
    getApiClient()
      .get<{
        data: components['schemas']['BlogItemResource'][]
        total: number
      }>('v1/blog')
      .then(({ data }) => data),

  getAdsData: () =>
    getApiClient()
      .get<components['schemas']['AdsDataResource'][]>('v1/content/main')
      .then(
        ({ data }) => data.find((obj) => obj.data.type === 'advertising').data
      ),

  getTranslate: (lang: string) =>
    getApiClient()
      .get<components['schemas']['Translate']>(`v1/localization/${lang}`)
      .then(({ data }) => data.data),

  showSpecialistsAndCompaniesCards: (
    showCards: components['schemas']['ShowSpecialistsAndCompaniesCards']
  ) =>
    getApiClient()
      .post('v1/profiles/specialists/show-limit/main', showCards)
      .then(({ data }) => data),
  getNewServicesSliderData: () =>
    getApiClient()
      .get('v1/content/slider/main')
      .then(({ data }) => data.data)
}
