import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useServicesStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: '112px 0',
      height: '100%',
      maxWidth: '1360px',
      margin: '0 auto',
      [theme.breakpoints.down(1440)]: {
        padding: '96px 32px'
      },
      [theme.breakpoints.down(900)]: {
        padding: '50px 32px 56px'
      }
    },
    title: {
      marginBottom: 24,
      textAlign: 'center',
      fontSize: '46px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      [theme.breakpoints.down(900)]: {
        fontSize: '26px',
        marginBottom: 16
      }
    },
    description: {
      maxWidth: 850,
      margin: '0 auto',
      textAlign: 'center',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '36px',
      paddingBottom: '48px',
      [theme.breakpoints.down(900)]: {
        fontSize: '16px',
        lineHeight: '22px',
        paddingBottom: '24px'
      }
    },
    cardRoot: {
      backgroundColor: '#EEEEEC',
      borderRadius: 12,
      padding: 32,
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      boxShadow: 'none',
      [theme.breakpoints.down(900)]: {
        padding: 24
      }
    },
    cardTitle: {
      fontSize: '28px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      [theme.breakpoints.down(900)]: {
        fontSize: '20px'
      }
    },
    cardDescription: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: 'normal'
    },
    cardList: {
      display: 'block',
      padding: 0,
      margin: 0
    },
    carListItem: {
      margin: '0 0 16px 16px',
      '&::marker': {
        color: '#4A3D3C'
      }
    },
    cardLink: {
      color: '#4A3D3C',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 'normal',
      textDecoration: 'underline'
    },
    cardButton: {
      fontSize: 16,
      fontWeight: 600,
      boxShadow: 'none',
      height: 40,
      width: 112,
      [theme.breakpoints.down(900)]: {
        fontSize: 14,
        marginTop: '16px'
      }
    },
    cardButtonText: {
      textTransform: 'none'
    },
    cardImage: {
      position: 'absolute',
      bottom: '28px',
      right: '32px',
      [theme.breakpoints.down(900)]: {
        bottom: '20px',
        right: '24px'
      }
    },
    cardActions: {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'end'
    },
    cardLongText: {
      paddingBottom: 26,
      [theme.breakpoints.down(900)]: {
        paddingBottom: 26
      }
    }
  })
)
