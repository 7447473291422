import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useServicesBigStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // paddingTop: '80px',
      padding: '80px 11% 0',
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down(800)]: {
        padding: '48px 11% 0'
      }
    },
    wrapper: {
      // paddingTop: '80px',
      maxWidth: '1400px'
    },
    textSliderWrapper: {
      // padding: '60px 0'
    },
    sliderTextWrapper: {
      flexDirection: 'column',
      gap: '32px',
      height: 'auto',
      padding: '32px 0 0 0',
      [theme.breakpoints.down(800)]: {
        padding: 0
      }
    },
    sliderItemHead: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'nowrap'
    },
    sliderControlItems: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down(800)]: {
        marginLeft: 16
      }
    },
    sliderItemTitle: {
      color: '#fff',
      fontSize: '56px',
      fontWeight: 700,
      lineHeight: 'normal',
      [theme.breakpoints.between(1200, 1500)]: {
        fontSize: '46px'
      },
      [theme.breakpoints.down(800)]: {
        fontSize: '32px'
      }
    },
    sliderButton: {
      [theme.breakpoints.down(800)]: {
        padding: 4
      },
      '& *': {
        stroke: '#fff'
      },
      '& path': {
        fill: '#fff'
      }
    },
    sliderPrevButton: {
      transform: 'rotate(180deg)'
    },
    sliderItemText: {
      color: '#fff',
      fontSize: '24px',
      lineHeight: '36px',
      fontWeight: 500,
      flex: '1 1 100%',
      [theme.breakpoints.between(1200, 1500)]: {
        fontSize: '20px'
      },
      [theme.breakpoints.down(800)]: {
        fontSize: '16px'
      }
    },
    // sliderItemCounters: {
    //   display: 'flex',
    //   flexDirection: 'row',
    //   justifyContent: 'space-between',
    //   gap: '5%',
    //   [theme.breakpoints.down(900)]: {
    //     flexWrap: 'wrap'
    //   }
    // },
    sliderCounter: {
      color: '#fff',
      fontSize: '56px',
      fontWeight: 600,
      lineHeight: 'normal',
      [theme.breakpoints.between(1200, 1500)]: {
        fontSize: '32px'
      },
      [theme.breakpoints.down(800)]: {
        fontSize: '32px'
      }
    },
    sliderCounterDescription: {
      color: '#fff',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 'normal',
      [theme.breakpoints.between(1200, 1500)]: {
        fontSize: '14px'
      },
      [theme.breakpoints.down(800)]: {
        fontSize: '14px'
      }
    },
    redirectButtonRoot: {
      width: 160,
      height: 56,
      fontSize: 16,
      fontWeight: 600,
      backgroundColor: '#fff',
      margin: '16px 0 30px',
      boxShadow: 'none',
      [theme.breakpoints.down(800)]: {
        margin: '16px 0 48px',
        width: '100%',
        height: 40,
        fontSize: 14,
        padding: '12px 0'
      }
    },
    redirectButtonLabel: {
      color: theme.palette.secondary.main,
      textTransform: 'none'
    },
    countersContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'space-between',
      rowGap: '16px'
    },
    countersItem: {
      flex: '1 1 33%'
    }
  })
)
