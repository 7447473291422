import React, { FC } from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import Image from 'next/image'
import { useWhereHelpsStyles } from './where-helps.style'

export const WhereHelps: FC = () => {
  const classes = useWhereHelpsStyles()
  return (
    <Container className={classes.wrapper}>
      <Grid container spacing={4}>
        <Grid item md={4}>
          <Typography className={classes.title}>
            Как мы можем вам помочь?
          </Typography>
          <Typography className={classes.description}>
            Все что нужно для бизнеса и специалистов мы объединили в одном месте
          </Typography>
        </Grid>
        <Grid item md className={classes.columnsWrapper}>
          <div className={classes.column}>
            <Typography className={classes.columnHeading}>
              Специалистам
            </Typography>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/1.svg"
                alt={'Специалистам 1'}
              />
              <Typography className={classes.columnText}>
                Найти постоянную или проектно-временную работу
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/2.svg"
                alt={'Специалистам 2'}
              />
              <Typography className={classes.columnText}>
                Создать портфолио для продвижения и вести свой Блог
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/3.svg"
                alt={'Специалистам 3'}
              />
              <Typography className={classes.columnText}>
                Бесплатно скачивать BIM-модели и пользоваться готовыми проектами
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/4.svg"
                alt={'Специалистам 3'}
              />
              <Typography className={classes.columnText}>
                Участвовать в проектах в онлайн офисе
              </Typography>
            </div>
          </div>
          <div className={classes.column}>
            <Typography className={classes.columnHeading}>Бизнесу</Typography>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/5.svg"
                alt={'Специалистам 4'}
              />
              <Typography className={classes.columnText}>
                Подобрать квалифици-рованных специалистов с портфолио и отзывами
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/6.svg"
                alt={'Специалистам 5'}
              />
              <Typography className={classes.columnText}>
                Получать заказы на комплексное проектирование
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/7.svg"
                alt={'Специалистам 6'}
              />
              <Typography className={classes.columnText}>
                Контролировать и управлять проектом онлайн
              </Typography>
            </div>
            <div className={classes.columnItem}>
              <Image
                width={80}
                height={80}
                src="/assets/new-landing/helps-icons/8.svg"
                alt={'Специалистам 7'}
              />
              <Typography className={classes.columnText}>
                Найти BIM-модель, готовый проект или оборудование от
                производителей
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
