/* eslint-disable max-len */
import { SERVICES_URL, TranslationDictionary } from '@plandi/common'

import { ReactComponent as ModelImg } from 'public/assets/new-landing/why/shop/3d-model.svg'
import { ReactComponent as ProjectsImg } from 'public/assets/new-landing/why/shop/project.svg'
import { ReactComponent as AnalysisImg } from 'public/assets/new-landing/why/shop/analysis.svg'
import { ReactComponent as WorkListImg } from 'public/assets/new-landing/why/shop/work-list.svg'
import { ReactComponent as OnlineShopImg } from 'public/assets/new-landing/why/shop/online-shop.svg'
import { ReactComponent as ArchivesImg } from 'public/assets/new-landing/why/shop/archives.svg'
import { ReactComponent as HandshakeImg } from 'public/assets/new-landing/why/office/handshake.svg'
import { ReactComponent as PMImg } from 'public/assets/new-landing/why/office/project-management.svg'
import { ReactComponent as VotingImg } from 'public/assets/new-landing/why/office/voting.svg'
import { ReactComponent as ComputerImg } from 'public/assets/new-landing/why/office/computer.svg'
import { ReactComponent as AgreementImg } from 'public/assets/new-landing/why/office/agreement.svg'
import { ReactComponent as TaskImg } from 'public/assets/new-landing/why/office/task.svg'
import { ReactComponent as ChronometerImg } from 'public/assets/new-landing/why/job/chronometer.svg'
import { ReactComponent as FolderImg } from 'public/assets/new-landing/why/job/folder.svg'
import { ReactComponent as RemoteImg } from 'public/assets/new-landing/why/job/remote.svg'
import { ReactComponent as GroupImg } from 'public/assets/new-landing/why/job/group.svg'
import { ReactComponent as MailImg } from 'public/assets/new-landing/why/job/mail.svg'
import { ReactComponent as DataMImg } from 'public/assets/new-landing/why/job/data-management.svg'
import { ReactComponent as CompassImg } from 'public/assets/new-landing/our-services/compass.svg'
import { ReactComponent as ModelingImg } from 'public/assets/new-landing/our-services/modeling.svg'
import { ReactComponent as ResumeImg } from 'public/assets/new-landing/our-services/resume.svg'

export const MAIN_SLIDERS = [
  {
    imageSrc:
      'https://storage.yandexcloud.net/plandi-storage/storage/main/tenders.jpg',
    title: 'Сервис для поиска \n стабильной  актуальной работы',
    subtitle: 'Проектировщикам и компаниям',
    button: {
      href: `${SERVICES_URL.job}/applicants`,
      text: 'Перейти к сервису «Фриланс и Работа»'
    }
  },
  {
    imageSrc:
      'https://storage.yandexcloud.net/plandi-storage/storage/main/office.jpg',
    title: 'Вы проектировщик? \n Проектная компания?',
    subtitle: 'Начните эффективно свой проект \n Онлайн сейчас!',
    button: {
      href: SERVICES_URL.office,
      text: 'Перейти в офис'
    }
  },
  {
    imageSrc:
      'https://storage.yandexcloud.net/plandi-storage/storage/main/shop.jpg',
    title: 'Сэкономь время и деньги!',
    subtitle: 'Купи готовый проект и начни \n строить прямо сейчас',
    button: {
      href: `${SERVICES_URL.shop}/models`,
      text: 'Перейти в Проектный маркет'
    }
  }
]

export const getOurServices = (translate?: TranslationDictionary['data']) => [
  {
    icon: CompassImg,
    title: translate?.['our_services'][0].title,
    href: '#office',
    subtitle: translate?.['our_services'][0].subtitle,
    details: translate?.['our_services'][0].details,
    button: {
      href: SERVICES_URL.office,
      disabled: false
    }
  },
  {
    icon: ResumeImg,
    title: 'Фриланс и Работа',
    href: '#job',
    subtitle: translate?.['our_services'][1].subtitle,
    details: translate?.['our_services'][1].details,
    button: {
      href: `${SERVICES_URL.job}/applicants`,
      disabled: false
    }
  },
  {
    icon: ModelingImg,
    title: translate?.['our_services'][2].title,
    href: '#shop',
    subtitle: translate?.['our_services'][2].subtitle,
    details: translate?.['our_services'][2].details,
    button: {
      href: `${SERVICES_URL.shop}/models`,
      disabled: false
    }
  }
]

export const STATS_OFFICE = [
  {
    value: '1.5х',
    text: 'Быстрее создается техническое задание'
  },
  {
    value: '650',
    text: 'Создано задач за все время'
  },
  {
    value: '800+',
    text: 'Специалистов вступило в команду проекта'
  }
]

export const STATS_JOB = [
  {
    value: '1.5х',
    text: 'Быстрее создается техническое задание'
  },
  {
    value: '550',
    text: 'Опубликовано всего заказов'
  },
  {
    value: '500+',
    text: 'Исполнителей и заказчиков'
  }
]

export const WHAT_SHOP = {
  id: 'shop',
  title: 'Что такое Проектный маркет?',
  subtitle: `PlanDi Проектный маркет - это бесплатная библиотека BIM объектов от
  производителей СНГ для проектировщиков и дизайнеров. А также магазин
  готовых проектных решений.`,
  details: [
    {
      title: 'Скачивание 3D BIM-моделей еще никогда не было таким быстрым',
      description: `Скачивание 3D BIM-моделей для проектировщиков и дизайнеров, ускорит
      работу без затрат времени на BIM-моделирование объектов.`,
      videoSrc:
        'https://storage.yandexcloud.net/plandi-storage/storage/movies-main/5.mp4'
    },
    {
      title: 'Удобный ресурс для производителей',
      description: `Производители смогут продвигать свои товары через опубликованные
      параметрические 3D BIM-модели напрямую через исполнителя заказчику и
      получать актуальную аналитику использования BIM-моделей.`,
      videoSrc:
        'https://storage.yandexcloud.net/plandi-storage/storage/movies-main/6.mp4'
    }
  ]
}

export const WHAT_OFFICE = {
  id: 'office',
  title: 'Что такое «Онлайн – Офис»',
  subtitle: `PlanDi «Онлайн – Офис» - это набор инструментов для успешной реализации
  проектов и эффективного контроля за командой проектными компаниями и
  проектировщиками-фрилансерами.`,
  videoSrc: 'https://www.youtube.com/embed/ddmhWLduftk',
  details: [
    {
      title: 'Встречайте - новый формат удаленной работы',
      description:
        'Работая в «Онлайн-Офис», PlanDi Вы не тратите время на поиск ссылок на нормативную документацию при составление ТЗ, поиск исполнителей и организацию рабочего процесса. Для Вас всё максимально автоматизировано. Просто пользуйтесь готовыми инструментами   «Онлайн-Офиса» для экономии времени и средств.',
      videoSrc:
        'https://storage.yandexcloud.net/plandi-storage/storage/movies-main/1.mp4'
    },
    {
      title: 'Многофункциональный «Онлайн – Офис»',
      description: `«Онлайн-Офиса» PlanDi – предоставляет оптимальные условия для
      обсуждения деталей проекта, постановки задач и контроля над их
      выполнением. Благодаря многофункциональным инструментам «Доска
      Задач», «Диаграмма Ганта», «Команда» и тд. Вы сможете управлять и
      вести проекты онлайн.`,
      videoSrc:
        'https://storage.yandexcloud.net/plandi-storage/storage/movies-main/2.mp4'
    }
  ]
}

export const WHAT_JOB = {
  id: 'job',
  title: 'Что такое «Фриланс и Работа»',
  subtitle: `PlanDi «Фриланс и Работа» - объединяет несколько видов площадок в одном сервисе
  и дает возможность найти работу, публиковать заказы, создавать свое
  портфолио, а также регистрировать организацию в онлайн-каталоге абсолютно бесплатно.`,
  details: [
    {
      title: 'Самый удобный способ найти компетентного специалиста',
      description: `Перед публикацией анкеты специалиста каждая анкета проходит
      проверку. Проектировщики публикуют свою анкету и работы на сервисе
      PlanDi «Фриланс и Работа», Вы можете проверить профили, портфолио и
      обзоры, прежде чем дать кому-то работу. Мы делаем удаленное
      сотрудничество удобным. С нашей помощью тысячи специалистов
      покинули тесные офисы и смогли реализовать свой потенциал в
      интересных проектах по СНГ. За удаленной работой – будущее, и мы
      делаем все для того, чтобы она была максимально комфортной.`,
      videoSrc:
        'https://storage.yandexcloud.net/plandi-storage/storage/movies-main/3.mp4'
    },
    {
      title: 'Сотрудники “удаленно” для проектных компаний',
      description: `Многие проектные компании нуждаются в квалифицированных
      специалистах, но не могут их найти в своем регионе. PlanDi
      «Фриланс и Работа» дает возможность бесплатно выбрать проектировщиков со
      всего СНГ и эффективно построить удаленную работу онлайн.`,
      videoSrc:
        'https://storage.yandexcloud.net/plandi-storage/storage/movies-main/4.mp4'
    }
  ]
}

export const WHY_SHOP = [
  {
    title: 'Бесплатное скачивание 3D BIM-моделей',
    subtitle:
      'Для скачивания BIM-модели и размещения проектного решения нужно только авторизоваться',
    image: ModelImg,
    size: 'small' as const
  },
  {
    title: 'Покупка проектных решений',
    subtitle:
      'Покупка проектных решений происходит напрямую через автора проекта',
    image: ProjectsImg,
    size: 'small' as const
  },
  {
    title: 'Актуальная информация',
    subtitle:
      'Мы предоставляем точную информацию о всех взаимодействиях пользователей на сайте с Вашим товаром.',
    image: AnalysisImg,
    size: 'bigOne' as const
  },
  {
    title: 'Все для проекта',
    subtitle:
      'Можете приобрести как готовые проекты так и готовые проектные решения прошедшие экспертизу.',
    image: WorkListImg,
    size: 'small' as const
  },
  {
    title: 'Цены производителей',
    subtitle:
      'При проектировании объектов у Вас есть возможность воспользоваться BIM-моделями с ценами от производителя на товар.',
    image: OnlineShopImg,
    size: 'bigTwo' as const
  },
  {
    title: 'Зарабатывайте на архиве',
    subtitle:
      'Публикация своих готовых проектов и проектных решений для дополнительного заработка',
    image: ArchivesImg,
    size: 'small' as const
  }
]

export const WHY_JOB = [
  {
    title: 'Быстрое размещение заказов',
    subtitle:
      'Для публикации заказа достаточно авторизоваться или пройти быструю регистрацию.',
    image: ChronometerImg,
    size: 'small' as const
  },
  {
    title: 'База специалистов и компаний',
    subtitle:
      'Актуальная база специалистов и компаний с портфолио, отзывами в строительной отрасли.',
    image: FolderImg,
    size: 'small' as const
  },
  {
    title: 'Свобода работать над любыми проектами',
    subtitle:
      'На PlanDi Вы ведете собственный бизнес и выбираете собственных партнеров и проекты сами. Заполните полноценно свой профиль и начинайте сотрудничество.',
    image: RemoteImg,
    size: 'bigOne' as const
  },
  {
    title: 'Современная платформа',
    subtitle:
      'Тысячи пользователей, гибкая система публикации проектов и вакансий, и конечно же точная система поиска.',
    image: GroupImg,
    size: 'small' as const
  },
  {
    title: 'Гибкая система уведомлений',
    subtitle:
      'Опишите задачу и желаемый результат, и мы моментально оповестим всех специалистов и компании с подходящими профилем, используя все доступные каналы.',
    image: MailImg,
    size: 'bigTwo' as const
  },
  {
    title: 'Создание вакансии',
    subtitle:
      'Для подбора сотрудника на постоянную, удаленную работу или частичную занятость.',
    image: DataMImg,
    size: 'small' as const
  }
]

export const WHY_OFFICE = [
  {
    title: 'Взаимодействие заказчика и исполнителя',
    subtitle:
      'С самого начала проекта Вы всегда будете осведомлены обо всех изменениях и сможете контролировать ход проекта.',
    image: HandshakeImg,
    size: 'small' as const
  },
  {
    title: 'Возможность эффективно выстроить работу',
    subtitle:
      'Используя наши инструменты Вы можете организовать продуктивную работу команды и оперативно реализовать задачи.',
    image: PMImg,
    size: 'small' as const
  },
  {
    title: 'Первый «Онлайн – Офис» в СНГ',
    subtitle:
      'Мы первый онлайн-офис в СНГ для проектировщиков и проектных компаний, который позволяет вести проекты удаленно с максимальной безопасностью.',
    image: VotingImg,
    size: 'bigOne' as const
  },
  {
    title: 'Безопасность',
    subtitle:
      'При регистрации все компании и специалисты проходят модерацию и верификацию.',
    image: ComputerImg,
    size: 'small' as const
  },
  {
    title: 'Нормативная документация',
    subtitle:
      'Нами максимально собрана и регулярно обновляется вся нормативная документация РФ по проектированию и строительству, которая доступна нашим пользователям.',
    image: AgreementImg,
    size: 'bigTwo' as const
  },
  {
    title: 'Контроль',
    subtitle:
      'Все требования и сроки по проекту зафиксированы в техническом задании и во всех задачах к проектам.',
    image: TaskImg,
    size: 'small' as const
  }
]

export const BLOG_ARTICLES = [
  {
    image:
      'https://storage.yandexcloud.net/plandi-storage/storage/main/blog-1.webp',
    title: 'Дополненная реальность AR: что это?',
    author: 'Plandi Info',
    date: '19.08.2020',
    text: 'Наряду с таким известным и достаточно широко используемым понятием, как виртуальная реальность, существует еще одно не менее интересное направление, способное изменить строительную отрасль – дополненная реальность AR. Ее применение дает застройщикам огромные преимущества, о которых многие из них даже не догадываются. Как работает дополненная реальность, и в чем заключаются ее достоинства? Сегодня мы приоткроемВами завесу тайны уникальной технологии. ',
    link: 'https://blog.plandi.ru/articles/149-dopolnennaya-realnost-ar-chto-eto-1'
  },
  {
    image:
      'https://storage.yandexcloud.net/plandi-storage/storage/main/blog-2.webp',
    title: 'Офис, который был балконом, для фрилансера',
    author: 'Plandi',
    date: '26.06.2020',
    text: 'Что нужно современному фрилансеру для комфортной работы? Самое важное условие успешного выполнения поставленных задач – наличие удобного рабочего места. К сожалению, габариты большинства городских квартир не дают возможности выделить под обустройство собственного кабинета отдельную комнату. Вместо нее вполне можно использовать балкон, который при правильном проектировании станет идеальным вариантом для офиса.',
    link: 'https://blog.plandi.ru/articles/101-ofis-kotoryy-byl-balkonom-dlya-frilansera-1'
  },
  {
    image:
      'https://storage.yandexcloud.net/plandi-storage/storage/main/blog-3.webp',
    title: 'Какие ошибки делают руководители на удалёнке?',
    author: 'Plandi',
    date: '26.06.2020',
    text:
      'Как Вы управляете удаленными сотрудниками? При чем не важно вся команда у вас работает удаленно или один сотрудник работает из дома, руководители должны иметь навыки мотивации и управления людьми, работающими вне офиса. Однако есть некоторые ошибки в удаленном управлении, которые влияют на производительность всей компании. Неорганизованность и отсутствие согласованности \n' +
      'Чтобы удаленная команда была продуктивна, нужно для всех сотрудников...',
    link: 'https://blog.plandi.ru/articles/117-kakie-oshibki-delayut-rukovoditeli-na-udalenke'
  }
]
