import React, { FC } from 'react'
import {
  Container,
  Grid,
  Typography,
  Button,
  Card,
  Link as MaterialLink,
  useTheme,
  useMediaQuery
} from '@material-ui/core'
import Link from 'next/link'
import { useServicesStyles } from './services.styles'
import Image from 'next/image'
import clsx from 'clsx'
import { useRouter } from 'next/router'

export const NewServices: FC = () => {
  const classes = useServicesStyles()
  const router = useRouter()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(900))
  return (
    <Container className={classes.root}>
      <Typography className={classes.title}>Наши сервисы и продукты</Typography>
      <Typography className={classes.description}>
        Комплексно решаем задачи на всех этапах проектирования от поиска
        специалиста до готового проекта!
      </Typography>
      <Grid container spacing={isMobile ? 3 : 7} alignItems={'stretch'}>
        <Grid item sm md={4}>
          <Card className={classes.cardRoot}>
            <Typography className={classes.cardTitle}>Работа</Typography>
            <Typography className={classes.cardDescription}>
              Поиск и публикация заказов для специалистов и бизнеса
            </Typography>
            <ul className={classes.cardList}>
              <li className={classes.carListItem}>
                <Link href={'/job/applicants'} passHref>
                  <MaterialLink className={classes.cardLink}>
                    Заказы и вакансии
                  </MaterialLink>
                </Link>
              </li>
              <li className={classes.carListItem}>
                <Link href={'/job/employers'} passHref>
                  <MaterialLink className={classes.cardLink}>
                    База специалистов
                  </MaterialLink>
                </Link>
              </li>
              <li className={classes.carListItem}>
                <Link href={'/job/companies'} passHref>
                  <MaterialLink className={classes.cardLink}>
                    Компании
                  </MaterialLink>
                </Link>
              </li>
              <li className={classes.carListItem}>
                <Link href={'/job/factories'} passHref>
                  <MaterialLink className={classes.cardLink}>
                    Заводы
                  </MaterialLink>
                </Link>
              </li>
            </ul>
            <div className={classes.cardActions}>
              <Button
                variant={'contained'}
                color={'secondary'}
                classes={{
                  root: classes.cardButton,
                  label: classes.cardButtonText
                }}
                onClick={() => {
                  router.push('/job')
                }}
              >
                Перейти
              </Button>
            </div>
            <div className={classes.cardImage}>
              <Image
                src={'/assets/new-landing/jobIcon.svg'}
                width={isMobile ? 72 : 88}
                height={isMobile ? 66 : 80}
                alt={'Job'}
              />
            </div>
          </Card>
        </Grid>
        <Grid item sm md={4}>
          <Card className={classes.cardRoot}>
            <Typography className={classes.cardTitle}>Маркет</Typography>
            <Typography className={classes.cardDescription}>
              Бесплатный подбор оборудования, Bim-моделей и готовых проектных
              решений для вашего проекта
            </Typography>
            <ul className={classes.cardList}>
              <li className={classes.carListItem}>
                <Link href={'/shop/projects'} passHref>
                  <MaterialLink className={classes.cardLink}>
                    Готовые проекты
                  </MaterialLink>
                </Link>
              </li>
              <li className={classes.carListItem}>
                <Link href={'/shop/models'} passHref>
                  <MaterialLink className={classes.cardLink}>
                    База BIM-моделей
                  </MaterialLink>
                </Link>
              </li>
              <li className={classes.carListItem}>
                <Link href={'/shop/goods'} passHref>
                  <MaterialLink className={classes.cardLink}>
                    Оборудование
                  </MaterialLink>
                </Link>
              </li>
            </ul>
            <div className={classes.cardActions}>
              <Button
                variant={'contained'}
                color={'secondary'}
                classes={{
                  root: classes.cardButton,
                  label: classes.cardButtonText
                }}
                onClick={() => router.push('/shop')}
              >
                Перейти
              </Button>
            </div>
            <div className={classes.cardImage}>
              <Image
                src={'/assets/new-landing/marketIcon.svg'}
                width={isMobile ? 72 : 88}
                height={isMobile ? 66 : 80}
                alt={'market'}
              />
            </div>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card className={clsx(classes.cardRoot, classes.cardLongText)}>
            <Typography className={classes.cardTitle}>Офис</Typography>
            <Typography className={classes.cardDescription}>
              Набор инструментов для управления вашими проектами и эффективного
              контроля за командой, а также глубокая интеграция с другими
              сервисами от PlanDi
            </Typography>
            <div className={classes.cardActions}>
              <Button
                variant={'contained'}
                color={'secondary'}
                classes={{
                  root: classes.cardButton,
                  label: classes.cardButtonText
                }}
                onClick={() =>
                  router.push(
                    `https://${
                      process.env.NEXT_PUBLIC_NODE_ENV === 'stage'
                        ? 'stage-office'
                        : 'office'
                    }.plandi.ru/board`
                  )
                }
              >
                Перейти
              </Button>
            </div>
            <div className={classes.cardImage}>
              <Image
                src={'/assets/new-landing/officeIcon.svg'}
                width={isMobile ? 72 : 88}
                height={isMobile ? 66 : 80}
                alt={'Office'}
              />
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
