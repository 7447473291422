import React, { FC } from 'react'
import { IconButton, Typography } from '@material-ui/core'
import { RoundedArrowIcon } from '../../../../jsx-ico'
import clsx from 'clsx'
import Button from '@material-ui/core/Button'
import { useServicesBigStyles } from '../services-big.styles'
import { useRouter } from 'next/router'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

interface ServicesTextItemProps {
  sliderItem: {
    title: string
    description: string
    count1: number
    description1: string
    count2: number
    description2: string
    count3: number
    description3: string
  }
  handlePrevSlide: () => void
  handleNextSlide: () => void
  link: string
}
export const ServicesTextItem: FC<ServicesTextItemProps> = ({
  sliderItem,
  handlePrevSlide,
  handleNextSlide,
  link
}) => {
  const classes = useServicesBigStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down(960))
  const router = useRouter()
  return (
    <>
      <div className={classes.sliderItemHead}>
        <Typography className={classes.sliderItemTitle}>
          {sliderItem.title}
        </Typography>
        <div className={classes.sliderControlItems}>
          <IconButton
            onClick={handlePrevSlide}
            aria-label={'sliderControl'}
            style={{ padding: isSmall ? 0 : undefined }}
          >
            <RoundedArrowIcon
              width={isSmall ? '40px' : '48px'}
              height={isSmall ? '40px' : '48px'}
              className={clsx(classes.sliderButton, classes.sliderPrevButton)}
            />
          </IconButton>
          <IconButton
            onClick={handleNextSlide}
            aria-label={'sliderControl'}
            style={{ padding: isSmall ? 0 : undefined }}
          >
            <RoundedArrowIcon
              width={isSmall ? '40px' : '48px'}
              height={isSmall ? '40px' : '48px'}
              className={classes.sliderButton}
            />
          </IconButton>
        </div>
      </div>
      <Typography className={classes.sliderItemText}>
        {sliderItem.description}
      </Typography>
      <div className={classes.countersContainer}>
        <div className={classes.countersItem}>
          <div className={classes.sliderCounter}>{sliderItem.count1}+</div>
          <div className={classes.sliderCounterDescription}>
            {sliderItem.description1}
          </div>
        </div>
        <div className={classes.countersItem}>
          <div className={classes.sliderCounter}>{sliderItem.count2}+</div>
          <div className={classes.sliderCounterDescription}>
            {sliderItem.description2}
          </div>
        </div>
        <div className={classes.countersItem}>
          <div className={classes.sliderCounter}>{sliderItem.count3}+</div>
          <div className={classes.sliderCounterDescription}>
            {sliderItem.description3}
          </div>
        </div>
      </div>
      <div>
        <Button
          variant={'contained'}
          classes={{
            root: classes.redirectButtonRoot,
            label: classes.redirectButtonLabel
          }}
          fullWidth={isSmall}
          onClick={() => router.push(link)}
        >
          Перейти
        </Button>
      </div>
    </>
  )
}
